import api from '@/plugins/axios'

const base = 'v1/tomador'

export default {
    userNeedSign() {
        return api.get(`${base}/condicoes-aprovadas/usuario-logado-precisa-assinar`)
    },
    getAllLastConditionApproved() {
        return api.get(`${base}/condicoes-aprovadas/ultimas`)
    },
    getLastConditionApproved() {
        return api.get(`${base}/condicoes-aprovadas/ultima`)
    },
    getTokenSMS(data) {
        return api.post(`${base}/condicoes-aprovadas/solicitar-token`, data)
    },
    signStatus() {
        return api.get(`${base}/condicoes-aprovadas/status-assinaturas`)
    },
    signCondition(data) {
        return api.post(`${base}/condicoes-aprovadas/assinar`, data)
    },
    getBankAccountPayment() {
        return api.get(`${base}/conta-bancaria-pagamento`)
    },
    getTelPartner() {
        return api.get(`${base}/socio/telefone`)
    }
}