<template>
  <div class="w-full flex justify-center z-40">
    <transition name="fade">
      <modal-nav v-show="modalnav" @closeModal="modalnav = false">
        <div slot="body" class="flex flex-col items-center mt-4">
          <img alt="Avatar" v-if="!isBusinessPartnerAccess" class="avatar" :src="$store.state.investor.avatar">
          <p v-if="!isBusinessPartnerAccess" class="font-bold my-2 text-sm">{{ fantasyName }}</p>
          <p v-if="!isBusinessPartnerAccess" class="text-xss">Status perfil: <span
              :class="incompleteProfile ? 'text-red-600' : 'text-primary'">{{ incompleteProfile ? 'incompleto' :
                  'completo'
              }}</span></p>
          <hr v-if="!isBusinessPartnerAccess" class="h-px w-full bg-black opacity-25 mt-6">
          <p v-if="!isBusinessPartnerAccess" class="link_dashboard"
            @click="modalnav = false; $router.push('/tomador/painel')">
            Página Inicial</p>
          <p v-if="!isBusinessPartnerAccess" class="link_dashboard"
            @click="modalnav = false; $router.push('/tomador/perfil/dados-empresa')">Dados da empresa</p>
          <!-- <p v-if="!isBusinessPartnerAccess" class="link_dashboard"
            @click="modalnav = false; $router.push('/tomador/perfil/dados-pessoais')">Meus dados</p>
          <p v-if="!isBusinessPartnerAccess" class="link_dashboard"
            @click="modalnav = false; $router.push('/tomador/perfil/quadro-socios')">Quadro de sócios</p>-->
          <p v-if="!isBusinessPartnerAccess" class="link_dashboard"
            @click="modalnav = false; $router.push('/tomador/perfil/documentos')">Central de documentos</p>
          <p v-if="!$store.state.isPartnerAccess && !isBusinessPartnerAccess" class="link_dashboard"
            @click="modalnav = false; showPasswordModal = true">Alterar senha</p>
          <p v-if="!$store.state.isPartnerAccess && !isBusinessPartnerAccess" class="link_dashboard"
            @click="modalnav = false; $router.push('/emprestimo')">Quero empréstimo</p>
          <p class="link_dashboard" @click="$store.dispatch('setLogout'); $router.push('/')">Sair</p>
        </div>
        <div slot="footer">
          <p class="text-center text-xss text-gray-500 mb-2">Copyright © TuTu Digital. Todos os Direitos Reservados.
            Tutu Digital Tecnologia Ltda – CNPJ 28.304.222/0001-17, Avenida Paulista, 1374 - Andar 11, São Paulo, SP.
            CEP 01310-100.</p>
        </div>
      </modal-nav>
    </transition>
    <transition name="fade">
      <change-password-modal v-if="showPasswordModal" @closeModal="showPasswordModal = false" req="tomador" />
    </transition>
    <header class="mt-4">
      <router-link class="logo" :to="$store.state.isPartnerAccess ? '/tomador/perfil' : '/tomador/painel'">
      </router-link>
      <img alt="Menu" @click="modalnav = true" class="bars" src="@/assets/images/menu.svg">
      <nav>
        <div v-if="!isBusinessPartnerAccess" class="flex flex-col justify-center items-end mr-4 text-sm">
          <p class="font-bold">{{ $store.state.business.fantasyName }}</p>
          <p class="text-xs">Status perfil: <span :class="incompleteProfile ? 'text-red-600' : 'text-primary'">{{
              incompleteProfile ? 'incompleto' :
                'completo'
          }}</span></p>
        </div>
        <div v-if="!isBusinessPartnerAccess" class="dropdown">
          <img alt="Avatar" class="avatar" :src="$store.state.investor.avatar">
          <div class="dropdown_content">
            <p class="dropdown_button" @click="$router.push('/tomador/painel')">Página Inicial</p>
            <p class="dropdown_button" @click="$router.push('/tomador/perfil/dados-empresa')">Dados da empresa</p>
            <!--  <p class="dropdown_button" @click="$router.push('/tomador/perfil/dados-pessoais')">Meus dados</p>
            <p class="dropdown_button" @click="$router.push('/tomador/perfil/quadro-socios')">Quadro de sócios</p>-->
            <p class="dropdown_button" @click="$router.push('/tomador/perfil/documentos')">Central de documentos</p>
            <p v-if="!$store.state.isPartnerAccess && !isBusinessPartnerAccess" class="dropdown_button"
              @click="showPasswordModal = true">Alterar senha</p>
            <p v-if="!$store.state.isPartnerAccess && !isBusinessPartnerAccess" class="dropdown_button"
              @click="$router.push('/emprestimo')">Nova Simulação</p>
            <p class="dropdown_button" @click="$store.dispatch('setLogout'); $router.push('/')">Sair</p>
          </div>
        </div>
        <p v-if="isBusinessPartnerAccess" class="cursor-pointer"
          @click="$store.dispatch('setLogout'); $router.push('/')">Sair</p>
      </nav>
    </header>
  </div>
</template>

<script>
import ModalNav from '@/components/Modal/ModalNav'
import ChangePasswordModal from '@/components/Modal/ChangePasswordModal'

export default {
  name: 'navbar',
  props: ['isBusinessPartnerAccess'],
  components: { ModalNav, ChangePasswordModal },
  data() {
    return {
      modalnav: false,
      contactModal: false,
      showPasswordModal: false,
      loadCount: 0,
      fantasyNameInitial: null
    }
  },
  computed: {
    incompleteProfile() {
      return this.$store.state.business.incompleteProfile
    },

    fantasyName() {
      return this.$store.state.business.fantasyName
    }
  },
  watch: {
    fantasyName() {
      if (!this.fantasyNameInitial && this.fantasyName) {
        this.fantasyNameInitial = '' + this.fantasyName;
      }

      if (this.fantasyName) {
        if (this.loadCount > 0 && this.fantasyNameInitial != this.fantasyName) {
          alert(`Você fez o login na conta de ${this.fantasyName} em outra aba, saindo da conta de ${this.fantasyNameInitial}`)
          location.reload();
        }
        this.loadCount++;
      }
    }
  }
}
</script>

<style lang="sass" scoped>

header
  @apply h-16 w-full flex flex-row items-center justify-between
  @screen md
    @apply h-16 w-full flex flex-row items-center justify-between
  @screen lg
    @apply h-16 w-full flex flex-row items-center justify-between

.logo
  @apply bg-no-repeat bg-center h-10 w-32 cursor-pointer bg-contain
  background-image: url("../../../assets/images/logo.svg")
  @screen lg
    @apply h-12 w-40

nav
  @apply hidden
  @screen md
    @apply block flex items-center
  @screen lg
    @apply block flex items-center

.bars
  @apply block w-6 h-6
  @screen md
    @apply hidden
  @screen lg
    @apply hidden

.avatar
  @apply bg-no-repeat bg-center h-14 w-14 shadow rounded-full object-cover
  @screen lg
    @apply cursor-pointer

.dropdown
  @apply relative inline-block
  &:hover .dropdown_content,
  &:focus .dropdown_content
      @apply block
  &_content
    @apply hidden absolute shadow-md z-40 bg-gray-100 text-xs right-0 rounded-sm
    min-width: 9rem
  &_button
    @apply p-3 no-underline block cursor-pointer
    &:hover
      @apply bg-gray-400
    &:first-child
      @apply rounded-t-sm
    &:last-child
      @apply rounded-b-sm

</style>
